<template>
  <div class="second-nav" ref="secondNavElem">
    <div class="search-container">
      <a-auto-complete
        :open="true"
        v-model:value="searchValue"
        class="certain-category-search"
        dropdown-class-name="certain-category-search-dropdown"
        :dropdown-match-select-width="false"
        :dropdown-style="{ width: '300px' }"
        size="large"
        :options="optionData"
        style="height: 30px"
        @change="onSelect"
      >
        <template #option="item">
          <template v-if="item?.options?.length">
            <span>
              {{ item.value }}
            </span>
          </template>
          <template v-else>
            <div>
              {{ item.label }}
              <span style="float: right"> {{ item.firstLevelName }} </span>
            </div>
          </template>
        </template>
        <a-input
          ref="inputRef"
          placeholder="搜索"
          size="small"
          allowClear
          @Focus.stop="onFocus"
          @Blur.stop="onBlur"
          @change="onChange"
          class=""
          :style="{ width: inputStyle }"
        >
          <template #prefix>
            <search-outlined class="certain-category-icon" />
          </template>
        </a-input>
      </a-auto-complete>
    </div>
    <div class="second-nav-item" ref="secondNavItemElem">
      <slot></slot>
    </div>
    <div class="drop-down" v-show="isDropDownShow" @click="scrollDown">
      <i class="iconfont">&#xe619;</i>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { queryMenuByName } from '@/pages/platform/resources/menu/menu.api'
import { SearchOutlined } from '@ant-design/icons-vue'
import { throttle } from 'lodash'
import { useNavTab } from '@/layout/store'

const inputRef = ref()
const isDropDownShow = ref<boolean>(false)
const secondNavElem = ref<HTMLElement | null>(null)
const secondNavItemElem = ref<HTMLElement | null>(null)
const searchValue = ref<string>('')
const optionData = ref()
const { pushTab } = useNavTab()
const inputStyle = ref('112px')

const updateIsDropDownShow = () => {
  if (secondNavElem.value && secondNavItemElem.value) {
    isDropDownShow.value = secondNavItemElem.value.clientHeight > secondNavElem.value.clientHeight
  }
}

const scrollDown = () => {
  if (secondNavElem.value) {
    secondNavElem.value.scrollTop = secondNavElem.value.scrollHeight
  }
}

let resizeTimer: number | undefined
const onResize = () => {
  if (resizeTimer) {
    clearTimeout(resizeTimer)
  }

  resizeTimer = window.setTimeout(() => {
    updateIsDropDownShow()
  }, 200)
}

const onFocus = () => {
  inputStyle.value = '300px'
}
const onBlur = () => {
  searchValue.value = ''
  inputStyle.value = '112px'
  optionData.value = []
}

function convertToAutoCompleteOption(raw: any[]) {
  return raw.map(l1 => ({
    value: l1.title,
    options: l1.children.map((c: any) => ({
      value: c.id,
      label: c.name,
      ...c,
    })),
  }))
}

const onChange = throttle((e: any) => {
  queryMenuByName({ name: e.target.value }).then((res: any) => {
    optionData.value = convertToAutoCompleteOption(res)
  })
}, 800)

const onSelect = (e: any, data: any) => {
  if (!(JSON.stringify(data) === '{}')) {
    inputRef.value.blur()
    setTimeout(() => {
      const { url, name } = data
      pushTab({ path: url, name })
    }, 300)
  }
}

onMounted(() => {
  window.addEventListener('resize', onResize)
})
onUpdated(() => {
  updateIsDropDownShow()
})
</script>
<style lang="less">
.second-nav {
  .search-container {
    .ant-select.certain-category-search {
      position: fixed;
      left: 67px;
      z-index: 1000;
      width: 100px !important;
    }
  }
}
.ant-select-dropdown.certain-category-search-dropdown {
  min-width: 100px !important;
}
</style>
