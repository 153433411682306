import type { IUserInfo, Pagination } from '@/types'
import type { IKeywords } from '@cris/cris-ui/lib/form/types'
import { omit, pick } from 'lodash-es'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { errorMessage, infoMessage } from './custom'

export const copy = (str: string) => {
  const input = document.createElement('textarea')
  document.body.appendChild(input)
  input.value = str
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
}

export const getQueryParams = (keywords: IKeywords, pagination?: Pagination) => {
  const queryParams = {
    ...omit(keywords, 'createdTime', 'updatedTime', '_searchOptions'),
    ...pick(pagination || {}, 'pageIndex', 'pageSize'),
  } as IKeywords & {
    createdStartTime: string | undefined
    createdEndTime: string | undefined
    updatedStartTime: string | undefined
    updatedEndTime: string | undefined
    pageIndex: number
    pageSize: number | undefined
  }
  return queryParams
}

export const getDomain = () => {
  const { hostname } = window.location
  const OfficeDomainMap: Record<string, string> = {
    localhost: 'localhost:5000',
    'saas.dev.cris.com': 'dev.cris.com',
    'saas.qa.cris.com': 'qa.cris.com',
    'saas.uat.cris.com': 'uat.cris.com',
    'saas.www.cris.com': 'www.cris.com',
  }

  return `//${OfficeDomainMap[hostname]}`
}

export const addressRegExp = /^[\u4e00-\u9fa5a-zA-Z0-9-_*！@#￥%……&*()（）~。，。？、【】]+$/

export const addressRule = {
  pattern: addressRegExp,
  message: '请输入中英文、数字或标点符号',
}

// 主题色
export const themeColor = '#0070cc'
export const defaultUploadFileAccept =
  '.jpg,.jpeg,.png,.doc,.docx,.pdf,.rar,.zip,.ppt,.pptx,.xlsx,.txt'
export const defaultUploadImgFileAccept = '.jpg,.jpeg,.png,.webp'

// 获取当前用户信息
export function getUserInfo(): IUserInfo | null {
  try {
    const userInfo = localStorage.getItem('userInfo')
    if (!userInfo) return null
    return JSON.parse(userInfo)
  } catch (error) {
    console.log(error)
    errorMessage('获取用户信息失败')
    return null
  }
}

/**
 * 合并tailwindcss 类名
 */

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

/**
 * 将对象的所有内部属性设置为 undefined
 */
export function setUndefined<T>(obj: T): T {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    // @ts-ignore
    obj[key] = undefined
  }
  return obj
}

/**
 * 暂未实现,敬请期待
 */
export const notImplemented = () => {
  infoMessage('暂未实现,敬请期待')
}
