import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const safe: RouteRecordRaw = {
  path: '/safe',
  component: LayoutComponent,
  redirect: '/safe/troubleshoot',
  children: [
    {
      path: '/safe/troubleshoot',
      meta: { title: '隐患排查治理' },
      children: [
        {
          path: '/safe/troubleshoot/check-sheet',
          meta: { title: '检查单配置' },
          component: () => import('../pages/safe/troubleshoot/check-sheet/index.vue'),
        },
        {
          path: '/safe/troubleshoot/check-vehicle-technical',
          meta: { title: '车辆技术检查' },
          component: () => import('../pages/safe/troubleshoot/check-vehicle-technical/index.vue'),
        },
        {
          path: '/safe/troubleshoot/inspection-plan',
          meta: { title: '安全检查' },
          component: () => import('../pages/safe/troubleshoot/inspection-plan/index.vue'),
        },
      ],
    },
    {
      path: '/safe/risk',
      meta: { title: '风险管理' },
      children: [
        {
          path: '/safe/risk/risk-library',
          meta: { title: '风险库' },
          component: () => import('../pages/safe/risk/risk-library/index.vue'),
        },
      ],
    },
    {
      path: '/safe/meeting',
      meta: { title: '安全例会' },
      component: () => import('../pages/safe/meeting/index.vue'),
    },
    {
      path: '/safe/responsibility',
      meta: { title: '责任书签订' },
      component: () => import('../pages/safe/responsibility/index.vue'),
    },
    {
      path: '/safe/driver/naturalization',
      meta: { title: '驾驶员管理' },
      children: [
        {
          path: '/safe/driver/naturalization',
          meta: { title: '驾驶员入籍' },
          component: () => import('../pages/safe/driver/naturalization/index.vue'),
        },
        {
          path: '/safe/driver/transfer',
          meta: { title: '驾驶员转籍' },
          component: () => import('../pages/safe/driver/transfer/index.vue'),
        },
        {
          path: '/safe/driver/score',
          meta: { title: '驾驶员记分' },
          component: () => import('../pages/safe/driver/score/index.vue'),
        },
        {
          path: '/safe/driver/evaluate',
          meta: { title: '驾驶员实习评定' },
          component: () => import('../pages/safe/driver/evaluate/index.vue'),
        },
        {
          path: '/safe/driver/punishment',
          meta: { title: '安全奖惩记录' },
          component: () => import('../pages/safe/driver/punishment/index.vue'),
        },
      ],
    },
    {
      path: '/safe/emergency',
      meta: { title: '应急管理' },
      children: [
        {
          path: '/safe/emergency/plan',
          meta: { title: '应急预案' },
          component: () => import('../pages/safe/emergency/plan/index.vue'),
        },
        {
          path: '/safe/emergency/team',
          meta: { title: '应急队伍' },
          component: () => import('../pages/safe/emergency/team/index.vue'),
        },
        {
          path: '/safe/emergency/drill',
          meta: { title: '应急演练' },
          component: () => import('../pages/safe/emergency/drill/index.vue'),
        },
        {
          path: '/safe/emergency/supplies',
          meta: { title: '应急物资' },
          component: () => import('../pages/safe/emergency/supplies/index.vue'),
        },
      ],
    },
    {
      path: '/safe/train',
      meta: { title: '教育培训' },
      children: [
        {
          path: '/safe/train/online',
          meta: { title: '线上培训' },
          component: () => import('../pages/safe/train/online/index.vue'),
        },
        {
          path: '/safe/train/scene',
          meta: { title: '现场培训' },
          component: () => import('../pages/safe/train/scene/index.vue'),
        },
        {
          path: '/safe/train/exam',
          meta: { title: '考题中心' },
          component: () => import('../pages/safe/train/exam/index.vue'),
        },
        {
          path: '/safe/train/learning',
          meta: { title: '安全学习' },
          component: () => import('../pages/safe/train/learning/index.vue'),
        },
      ],
    },
    {
      path: '/safe/institution',
      meta: { title: '机构管理' },
      children: [
        {
          path: '/safe/institution',
          meta: { title: '管理机构' },
          component: () => import('../pages/safe/institution/institution/index.vue'),
        },
        {
          path: '/safe/institution/user',
          meta: { title: '管理人员' },
          component: () => import('../pages/safe/institution/user/index.vue'),
        },
      ],
    },
  ],
}
