import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const settings: RouteRecordRaw = {
  path: '/settings',
  component: LayoutComponent,
  redirect: '/settings/staff',
  children: [
    {
      path: '/settings/staff',
      meta: {
        title: '员工管理',
      },
      component: () => import('../pages/settings/staff/staff-index.vue'),
    },
    {
      path: '/settings/organization/self-settings',
      meta: {
        title: '个人设置',
      },
      component: () =>
        import('../pages/settings/organization/self-settings/self-settings-index.vue'),
    },
    {
      path: '/settings/organization/settlement-type',
      meta: {
        title: '结算方式',
      },
      component: () =>
        import('../pages/settings/organization/settlement-type/settlement-type-index.vue'),
    },
    {
      path: '/settings/organization/organization',
      meta: {
        title: '组织管理',
      },
      component: () => import('../pages/settings/organization/organization/organization-index.vue'),
    },
    {
      path: '/settings/organization/role',
      meta: {
        title: '角色管理',
      },
      component: () => import('../pages/settings/organization/role/role-index.vue'),
    },
    {
      path: '/settings/organization/organization-menu',
      meta: {
        title: '组织菜单',
      },
      component: () =>
        import('../pages/settings/organization/organization-menu/organization-menu-index.vue'),
    },
    {
      path: '/settings/workflow/set',
      meta: {
        title: '流程设置',
      },
      component: () => import('@/pages/settings/workflow/flow-model-index.vue'),
    },
    {
      path: '/settings/prefer-setting',
      meta: {
        title: '偏好设置',
      },
      component: () => import('@/pages/settings/prefer-setting/prefer-setting-index.vue'),
    },
  ],
}
