<template>
  <div class="menu-aside" @mouseenter="handleMouseEnterMenu" @mouseleave="onHoverMenuItem(-1)">
    <div class="avatar">
      <a-popover
        placement="bottomRight"
        trigger="click"
        :overlayClassName="'avatar-popover'"
        v-model:visible="avatarPopoverVisible"
      >
        <template #content>
          <div class="avatar-popover-container">
            <div class="line-one">
              <Avatar size="50" :uuid="user?.avatar" :name="user?.name"></Avatar>
              <!-- <img class="rounded-lg" v-if="user.avatar" :src="avatar" alt="avatar" title="头像" />
              <div v-else class="headFirst">
                {{ user?.name?.substring(0, 1) }}
              </div> -->
              <div class="ml-4">
                <div class="text-xl font-bold">{{ user?.employeeName }}</div>
                <div class="mt-1 text-gray-600">{{ organizationName }}</div>
              </div>
            </div>
            <a-divider />
            <div
              class="item cursor-pointer"
              id="SwitchOrganizationPopupContainer"
              v-if="organization?.length >= 1"
            >
              <a-popover
                placement="rightTop"
                trigger="hover"
                :overlayClassName="'switch-organization-popover'"
                :getPopupContainer="getSwitchOrganizationPopupContainer"
              >
                <template #content>
                  <div class="menu-content">
                    <div
                      v-for="o in organization"
                      :key="o.id"
                      :class="{ active: organizationId === o.id }"
                      class="organization-item cursor-pointer"
                      :title="o.organizationName"
                      @click="goChangeOrganization(o.id)"
                    >
                      <i class="iconfont" v-html="'&#xe745;'"></i>
                      <span>{{ o.organizationName }}</span>
                      <CheckOutlined v-if="organizationId === o.id" />
                    </div>
                  </div>
                  <div class="pt-2" style="position: sticky; bottom: 0">
                    <span class="item-content">
                      <a-button block @click="clickChangeOrganization">
                        返回组织选择</a-button
                      ></span
                    >
                  </div>
                </template>

                <div class="change-organization">
                  切换组织 <i class="iconfont" v-html="'&#xe7da;'"></i>
                </div>
              </a-popover>
            </div>
            <a-divider />
            <div class="item cursor-pointer" @click="goOfficeHomePage">
              <div class="item-content">官网首页</div>
            </div>
            <a-divider />
            <div class="item cursor-pointer" @click="handleInvite">
              <div class="item-content">邀请同事加入</div>
            </div>
            <div class="item cursor-pointer" @click="goSettings">
              <div class="item-content">设置</div>
            </div>
            <a-divider />
            <div class="item cursor-pointer" @click="goLogout">
              <div class="item-content">退出</div>
            </div>
          </div>
        </template>
        <Avatar :uuid="user?.avatar" :name="user?.name"></Avatar>
      </a-popover>
    </div>

    <ul class="menu-list">
      <div
        v-for="{ blockBoxKey, blockBoxName } in blockBoxList"
        :key="blockBoxKey"
        :class="`block-box block-box-${blockBoxName}`"
      >
        <div v-for="item in menuAsideData.filter(m => m.blockType === blockBoxKey)" :key="item.id">
          <li
            :class="getClassName(item.id)"
            @mouseenter="onHoverMenuItem(item.id)"
            @click="onClickMenuItem(item)"
          >
            <a :target="isOuterLink(item) ? '_blank' : undefined">
              <i class="iconfont" v-html="item.icon"></i>
              <span>{{ item.menuName }}</span>
              <em class="aside-tips" v-show="item.asideTips"></em>
            </a>
          </li>
        </div>
      </div>
      <ResetLS />
    </ul>

    <div
      :class="['secondNavControl', secondNavShowRef ? 'open' : 'close']"
      @click="updateSecondNavShow"
      @mouseenter="handleMouseEnterMenu"
      @mouseleave="handleMouseLeaveMenu"
      v-if="show"
    >
      <i v-if="secondNavShowRef" class="iconfont" v-html="'&#xe63a;'"></i>
      <i v-else class="iconfont" v-html="'&#xe63b;'"></i>
    </div>

    <SecondNav
      :class="[
        secondNavShowRef ? 'openSecondNav' : 'closeSecondNav',
        hoverSecondNavStatusRef ? 'hoverSecondNav' : 'null',
      ]"
    >
      <SecondNavItem
        :itemData="menuNavDataRef"
        :cacheMenuInfo="cacheMenuInfo"
        :cacheMenuInfoCount="cacheMenuInfoCount"
        @secondNavClick="handleSecondNavClick"
      />
    </SecondNav>
  </div>

  <InviteModal :visible="inviteVisible" @close="inviteVisible = false" :inviteToken="inviteToken" />
</template>

<script lang="ts">
import { defineComponent, toRefs, ref, onMounted, watch, computed, onUnmounted } from 'vue'
import type { PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CheckOutlined } from '@ant-design/icons-vue'
import { useUser } from '@/stores/user'
import { useOrganization } from '@/stores/organization'
import { preview } from '@/utils/http'
import {
  changeOrganization,
  generateInvitedToken,
  logout,
  queryLoginInfo,
  listOrganization,
} from '@/pages/entrance/entrance.api'
import { storeToRefs } from 'pinia'
import { queryUserEmployeeInfo } from '@/pages/platform/user/staff/staff.api'
import { getDomain } from '@/utils'
import InviteModal from '@/pages/platform/user/staff/invite-modal.vue'
import { Avatar } from '@cris/cris-ui'
import { message } from 'ant-design-vue'
import { navHrefTransfer } from '../utils'
import SecondNavItem from './second-nav-item/secondNavItem.vue'
import SecondNav from './second-nav/secondNav.vue'
import type { MenuAsideNavData } from '../types/menuAsideType'
import { useNavTab } from '../store'
import type { ResponseData } from '@/pages/entrance/entrance'
import ResetLS from './ResetLS.vue'

const MenuAsideProps = {
  menuAsideData: {
    type: Array as PropType<MenuAsideNavData[]>,
    default: () => [],
  },
  // 当前选择的MenuItem的id
  selectedMenuIdRef: {
    type: Number as PropType<number>,
    default: 0,
  },
  // 二级导航的状态
  secondNavStatus: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  cacheMenuInfo: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  cacheMenuInfoCount: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
  clickAction: {
    type: Function as PropType<(i: any) => void>,
  },
}
// updateSelectIndex根地址栏找到对应的一级导航id
const selectedMenuIdRef = ref<number>(0)
const hoverMenuIdRef = ref<number>(selectedMenuIdRef.value)
const menuNavIdRef = ref<number>(selectedMenuIdRef.value)
const selectIndex = (path: string, d: number, menuAsideData: MenuAsideNavData[]) => {
  const treeNodes = menuAsideData
  for (let i = 0; i < treeNodes.length; i += 1) {
    const treeNode = treeNodes[i]
    if (treeNode.children.length) {
      for (let j = 0; j < treeNode.children.length; j += 1) {
        const nodeChild = treeNode.children[j]

        if (nodeChild.url !== '' && path.startsWith(nodeChild.url)) {
          return i
        }
        if (nodeChild.children.length > 0) {
          for (let k = 0; k < nodeChild.children.length; k += 1) {
            const grandChild = nodeChild.children[k]
            if (grandChild.url !== '' && path.startsWith(grandChild.url)) {
              return i
            }
          }
        }
      }
    }
  }
  return d
}
const updateSelectIndex = (path: string, menuAsideData: MenuAsideNavData[]) => {
  const index = selectIndex(path, 0, menuAsideData)
  const { id } = menuAsideData[index]
  menuNavIdRef.value = id
  hoverMenuIdRef.value = id
}
const inviteVisible = ref(false)
const inviteToken = ref()

let rowHeightTimer: any
const show = ref(false)

const handleMouseEnterMenu = () => {
  show.value = true
  clearTimeout(rowHeightTimer)
}
const handleMouseLeaveMenu = () => {
  rowHeightTimer = setTimeout(() => {
    show.value = false
  }, 500)
}

const selectedSecondIndex = (
  index: number,
  path: string,
  menuAsideData: MenuAsideNavData[],
): [number, number?] | -1 => {
  const treeNodes = menuAsideData
  if (index === -1) return -1
  const [p] = path.split('?')
  const pNode = treeNodes[index]
  if (pNode === undefined) return -1
  if (pNode.children === undefined) return -1

  for (let i = 0; i < pNode.children.length; i += 1) {
    const sonNode = pNode.children[i].children
    if (sonNode) {
      for (let j = 0; j < sonNode.length; j += 1) {
        if (sonNode[j].url === p) return [i, j]
      }
    }
    if (pNode.children[i].url === p) return [i]
  }

  return -1
}

export default defineComponent({
  name: 'MenuAside',
  components: {
    SecondNav,
    SecondNavItem,
    CheckOutlined,
    InviteModal,
    Avatar,
    ResetLS,
  },
  data() {
    return {
      blockBoxList: [
        { blockBoxKey: '0', blockBoxName: 'top' },
        { blockBoxKey: '1', blockBoxName: 'middle' },
        { blockBoxKey: '2', blockBoxName: 'bottom' },
      ],
    }
  },
  props: MenuAsideProps,
  watch: {
    // 点击状态下的开关
    // #/status/switch 开关高亮
    // 点击详情
    // #/detail/collapse 折叠面板高亮
    // 点击状态
    // #/status/brand-name   开关高亮、品牌/系列高亮
    // 点击状态下的icon
    // #/status/icon-cell   icon、开关高亮
    // watch  updateSelectIndex传地址进去
    $route: {
      deep: true,
      immediate: true,
      handler(to, from = {}) {
        // debugger
        if (to.meta?.skipMenu) return
        updateSelectIndex(`/${to.href}`, this.menuAsideData)
        const fIndex = selectIndex(`/${from.href}`, -1, this.menuAsideData)
        const tIndex = selectIndex(`/${to.href}`, -1, this.menuAsideData)
        // const value = updateSelectIndex(`/${to.href}`) || 0;
        this.menuNavDataRef = this.menuAsideData[tIndex]?.children
        if (this.menuAsideData[fIndex] !== undefined) {
          const uNavData = this.menuAsideData[fIndex].children
          const uSelect = selectedSecondIndex(fIndex, `/${from.href}`, this.menuAsideData)
          if (uSelect !== -1) {
            if (uNavData[uSelect[0]] !== undefined) {
              uNavData[uSelect[0]].selected = false
              if (uSelect[1] !== undefined && uNavData[uSelect[0]].children?.[uSelect[1]]) {
                uNavData[uSelect[0]].children[uSelect[1]].selected = false
              }
            }
          }
        }
        const selectC = selectedSecondIndex(tIndex, `/${to.href}`, this.menuAsideData)
        if (selectC !== -1) {
          this.menuNavDataRef[selectC[0]].selected = true
          if (selectC[1] !== undefined && this.menuNavDataRef[selectC[0]].children?.[selectC[1]]) {
            this.menuNavDataRef[selectC[0]].children[selectC[1]].selected = true
          }
        }
      },
    },
  },
  setup(props) {
    const { menuAsideData, secondNavStatus, cacheMenuInfo, cacheMenuInfoCount, clickAction } =
      toRefs(props)

    const route = useRoute()
    const router = useRouter()

    const { setUser } = useUser()
    const { setCurrentOrganizationId } = useOrganization()
    const { setOrganization } = useOrganization()
    const store = useOrganization()
    const { organization, organizationId } = storeToRefs(store)
    const userStore = useUser()
    const { user } = storeToRefs(userStore)

    const organizationName = computed(() => {
      const org = organization.value?.find((o: any) => o.id === organizationId.value)
      if (org) {
        return org.organizationName
      }
      return ''
    })

    const { pushTab } = useNavTab()
    const avatar = ref()

    const visibilitychangeHandler = () => {
      // eslint-disable-next-line
      if (organizationId.value != localStorage.organizationId) {
        message.warning('检测到您已切换组织，系统将自动帮您切换').then(() => {
          localStorage.setItem('refresh', '1')
          window.location.replace('/#/home/index')
          window.location.reload()
        })
      } else {
        queryLoginInfo()
      }
    }

    onMounted(() => {
      // if (!organization.value?.length) {
      listOrganization().then((data = []) => {
        setOrganization(data)
      })
      // }

      queryLoginInfo().then((res: ResponseData) => {
        setUser(res)
        if (res.organizationId) {
          setCurrentOrganizationId(
            res.organizationId,
            res.organizationType,
            res.organizationAppendTypeId,
          )
        }
        avatar.value = preview(res.avatar as string)
        queryUserEmployeeInfo().then((data: any) => {
          setUser({ ...res, employeeName: data.employeeName })
        })
      })

      window.addEventListener('visibilitychange', visibilitychangeHandler, false)
    })

    onUnmounted(() => {
      window.removeEventListener('visibilitychange', visibilitychangeHandler, false)
    })

    const avatarPopoverVisible = ref<boolean>(false)

    const hoverSecondNavStatusRef = ref<boolean>(false) // 悬停二级菜单是否显示
    const secondNavShowRef = ref<boolean>(secondNavStatus.value) // 二级菜单是否显示
    const menuNavDataRef = ref<MenuAsideNavData[]>(
      menuAsideData.value[selectIndex(`/#${route.path}`, 0, props.menuAsideData)].children,
    ) // 当前选中一级菜单的二级菜单数据

    // normal元素类名
    const getClassName = (id: number) => {
      const select = menuNavIdRef.value === id ? 'select' : ''
      const active = hoverMenuIdRef.value === id ? 'active' : ''
      const block = menuAsideData.value.find(m => m.id === id)?.menuName === '' ? 'block' : ''
      return `${select} ${active} ${block}`
    }

    const isOuterLink = (item: MenuAsideNavData) => {
      return item.url && item.url.startsWith('http')
    }

    // 二级导航栏显示状态
    const updateSecondNavShow = () => {
      secondNavShowRef.value = !secondNavShowRef.value
      if (secondNavShowRef.value === false) {
        show.value = false
      }
    }

    const handleSecondNavClick = () => {
      // secondNavShowRef.value = true
    }

    // 鼠标悬浮
    const onHoverMenuItem = (id: number) => {
      handleMouseLeaveMenu()
      const index = menuAsideData.value.findIndex(m => m.id === id)
      if (menuAsideData.value[index] != null && menuAsideData.value[index].menuName !== '') {
        hoverMenuIdRef.value = id
        menuNavDataRef.value = menuAsideData.value[index].children // 将悬浮项子菜单的数据放进侧边栏中

        if (!secondNavShowRef.value) {
          hoverSecondNavStatusRef.value = true // 如果二级菜单是折叠状态，则展开悬停二级菜单
        }
        // 否则为鼠标离开时的情况
      } else {
        const value: number = selectIndex(`/#${route.path}`, 0, props.menuAsideData)
        hoverMenuIdRef.value = menuNavIdRef.value
        menuNavDataRef.value = menuAsideData.value[value].children // 将选中项放入侧边栏中
        hoverSecondNavStatusRef.value = false // 强制关闭悬停二级菜单
      }
    }

    // 点击菜单
    const onClickMenuItem = (item: MenuAsideNavData) => {
      if (isOuterLink(item)) {
        return
      }
      const { id, subMenuType } = item
      secondNavShowRef.value = subMenuType !== '1'
      const index = menuAsideData.value.findIndex(m => m.id === id)
      if (menuAsideData.value[index] != null && menuAsideData.value[index].menuName !== '') {
        const clickAct = clickAction.value as (i: number) => void
        menuNavIdRef.value = id
        menuNavDataRef.value = menuAsideData.value[index].children
        clickAct(index)
        let target = item

        if (menuAsideData.value[index].children[0]) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;[target] = menuAsideData.value[index].children

          if (menuAsideData.value[index].children[0].children[0]) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;[target] = menuAsideData.value[index].children[0].children
          }
        }
        pushTab({ path: navHrefTransfer(target.url), name: target.menuName })
      }
    }

    // 菜单消息提示
    const isMenuInfo = (data: MenuAsideNavData) => {
      const cacheIndex = cacheMenuInfo.value.indexOf(data.url)

      if (cacheIndex !== -1) {
        if (cacheMenuInfoCount.value[cacheIndex] !== 0) {
          return true
        }
      }

      return false
    }

    // 判断子菜单有没有消息提示
    const isHaveInfoTip = (data: MenuAsideNavData) => {
      let result = false

      const recursionData = (rData: MenuAsideNavData) => {
        if (rData.children.length > 0) {
          rData.children.forEach(item => {
            const curResult = isMenuInfo(item)

            if (curResult) {
              result = curResult
            } else {
              recursionData(item)
            }
          })
        }
      }

      result = isMenuInfo(data)

      if (result) {
        return result
      }
      recursionData(data)

      return result
    }

    const handleInvite = () => {
      avatarPopoverVisible.value = false
      inviteVisible.value = true
      generateInvitedToken().then((res: any) => {
        inviteToken.value = res
      })
    }

    const goOfficeHomePage = () => {
      avatarPopoverVisible.value = false
      window.location.href = getDomain()
    }

    const goSettings = () => {
      avatarPopoverVisible.value = false
      pushTab({ path: '/settings/organization/self-settings', name: '个人设置' })
    }

    const clickChangeOrganization = () => {
      router.push('/organization')
    }

    const goLogout = () => {
      logout({ token: user.value.token as string }).then(() => {
        router.replace({ path: '/login' }).then(() => window.location.reload())
      })
    }

    const goChangeOrganization = (id: number) => {
      changeOrganization(id).then(res => {
        localStorage.removeItem('organizationId')
        setCurrentOrganizationId(id, res.organizationType, res.organizationAppendTypeId)
        setUser(res)
        localStorage.setItem('refresh', '1')
        window.location.replace('/#/home/index')
        window.location.reload()
      })
    }

    const getSwitchOrganizationPopupContainer = () => {
      return window.document.querySelector('#SwitchOrganizationPopupContainer')
    }

    onMounted(() => {
      menuAsideData.value.forEach(item => {
        if (isHaveInfoTip(item)) {
          item.asideTips = true
        }
      })

      watch(props.cacheMenuInfoCount, () => {
        menuAsideData.value.forEach(item => {
          if (isHaveInfoTip(item)) {
            item.asideTips = true
          }
        })
      })
    })

    return {
      menuAsideData,
      getClassName,
      secondNavShowRef,
      hoverSecondNavStatusRef,
      menuNavDataRef,
      cacheMenuInfo,
      cacheMenuInfoCount,
      updateSecondNavShow,
      onHoverMenuItem,
      onClickMenuItem,
      navHrefTransfer,
      isOuterLink,
      handleSecondNavClick,
      user,
      organizationName,
      handleInvite,
      inviteVisible,
      inviteToken,
      avatar,
      goOfficeHomePage,
      goSettings,
      goLogout,
      clickChangeOrganization,
      organization,
      organizationId,
      goChangeOrganization,
      getSwitchOrganizationPopupContainer,
      avatarPopoverVisible,
      show,
      handleMouseEnterMenu,
      handleMouseLeaveMenu,
      // handleMouseEnterMenus
    }
  },
})
</script>
<style scoped lang="less">
:deep(.ant-popover-content) {
  .ant-popover-inner-content {
    .menu-content {
      position: relative;
      overflow-y: auto;
      max-height: 800px;
    }
  }
}
</style>
