import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const archives: RouteRecordRaw = {
  path: '/archives',
  component: LayoutComponent,
  redirect: '/archives/vehicle',
  children: [
    {
      path: '/archives/vehicle',
      meta: {
        title: '车辆档案',
      },
      component: () => import('../pages/archives/vehicle/index.vue'),
    },
    {
      path: '/archives/user',
      meta: { title: '人员档案' },
      children: [
        {
          path: '/archives/user/driver',
          meta: {
            title: '驾驶员',
          },
          component: () => import('../pages/archives/user/driver/index.vue'),
        },
        {
          path: '/archives/user/blacklist',
          meta: {
            title: '黑名单',
          },
          component: () => import('../pages/archives/user/blacklist/index.vue'),
        },
      ],
    },
  ],
}
