<template>
  <div id="layout" class="layout" :style="zoomStyle" v-if="menuAsideDataRef.length > 0">
    <div class="main-wrap">
      <MenuAside
        :menuAsideData="menuAsideDataRef"
        :secondNavStatus="secondNavStatus"
        :selectedMenuItem="firstNavSelectIndex"
        :cacheMenuInfo="cacheMenuInfoRef"
        :cacheMenuInfoCount="cacheMenuInfoCountRef"
        :clickAction="clickAction"
      />
      <MainIndex>
        <slot></slot>
      </MainIndex>
      <FooterIndex />
      <EntranceButton @zoomIn="handleZoomIn" @zoomOut="handleZoomOut" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide } from 'vue'
import type { PropType } from 'vue'
import type { MenuAsideNavData } from './types/menuAsideType'
import { apiMenuAsideData } from './apiMenuAside'
import MenuAside from './menu-aside/menuAside.vue'
import MainIndex from './main/main-index.vue'
import FooterIndex from './footer/footer-index.vue'
import EntranceButton from './entrance-button.vue'

const LayoutProps = {
  secondNavStatus: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  baseUrl: {
    type: String as PropType<string>,
    default: '/',
  },
  selectedUrl: {
    type: String as PropType<string>,
    default: '',
  },
  asideMenus: {
    type: Array as PropType<MenuAsideNavData[]>,
    default: false,
    require: false,
  },
}

export default defineComponent({
  name: 'LayoutIndex',
  components: {
    MainIndex,
    FooterIndex,
    MenuAside,
    EntranceButton,
  },
  props: LayoutProps,
  setup(props) {
    // const { baseUrl, selectedUrl } = toRefs(props);
    const menuAsideDataRef = ref<MenuAsideNavData[]>([]) // menu渲染数据
    const cacheMenuInfoRef = ref<string[]>([]) // 缓存菜单信息
    const cacheMenuInfoCountRef = ref<number[]>([]) // 缓存菜单信息数量
    const firstNavSelectIndex = ref<number>(0) // 一级菜单选中状态
    const zoomLevel = ref(1)
    const zoomStyle = computed(() => {
      return {
        transform: `scale(${zoomLevel.value})`,
        transformOrigin: '0 0',
        width: `${100 / zoomLevel.value}%`,
        height: `${100 / zoomLevel.value}%`,
      }
    })

    provide('baseUrl', props.baseUrl)

    // menu待处理事项提示
    // const getMenuInfo = () => {
    //   menuInfoRef.value = (menuJson as any).info.data;

    //   menuInfoRef.value.forEach((item) => {
    //     cacheMenuInfoRef.value.push(item.menuUrl);
    //     cacheMenuInfoCountRef.value.push(item.unprocessedCount);
    //   });
    // };
    // getMenuInfo();

    // menu跳转链接
    const getMenuUrl = (data: MenuAsideNavData): string => {
      if (!data) {
        return ''
      }

      if (data.url) {
        return data.url
      }
      return getMenuUrl(data.children[0])
    }

    const clickAction = (index: number) => {
      firstNavSelectIndex.value = index
    }

    // 处理props
    const handleProps = () => {
      if (menuAsideDataRef.value) {
        menuAsideDataRef.value.forEach(item => {
          item.url = getMenuUrl(item)
        })

        menuAsideDataRef.value.forEach((item, index) => {
          if (item.selected) {
            firstNavSelectIndex.value = index
          }
        })
      }
    }
    handleProps()

    // 获取menu数据
    // menuAsideDataRef.value一级导航的数据
    const getMenuAsideData = async () => {
      if (props.asideMenus) {
        menuAsideDataRef.value = props.asideMenus
      } else {
        const res = await apiMenuAsideData(props.baseUrl as string, props.selectedUrl)

        if (res && res.code === 0) {
          menuAsideDataRef.value = res.data.treeNodes
        }
      }
    }
    getMenuAsideData()

    const handleZoomIn = () => {
      zoomLevel.value += 0.01
    }

    const handleZoomOut = () => {
      zoomLevel.value -= 0.01
    }

    return {
      menuAsideDataRef,
      firstNavSelectIndex,
      cacheMenuInfoRef,
      cacheMenuInfoCountRef,
      clickAction,
      handleZoomIn,
      handleZoomOut,
      zoomStyle,
    }
  },
})
</script>
<style lang="less">
@import url('~@cris/cris-ui/lib/styles/basic.less');
@import url('./style.less');
#layout {
  overflow: hidden;
}
.ant-popover.avatar-popover {
  z-index: 2000;
  padding-top: 2px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 18px 8px 8px;
    color: #000000;
    .avatar-popover-container {
      width: 300px;
      .ant-divider-horizontal {
        margin: 8px 0 8px 3%;
        width: 94%;
        min-width: initial;
      }
      .line-one {
        display: flex;
        align-items: center;
        margin: 0 8px 18px;
        box-sizing: border-box;
        .username {
          padding-left: 10px;
        }
        img {
          max-width: 50px;
          height: 50px;
        }
      }
      .item {
        padding: 0 10px;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        &:hover {
          background: #f1f1f1;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .change-organization {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}
.ant-popover.switch-organization-popover {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 8px;
    width: 280px;
    color: #000000;
    .organization-item {
      display: flex;
      align-items: center;
      padding: 2px 7px;
      border-radius: 5px;
      &:hover {
        background: #f1f1f1;
      }
      span {
        overflow: hidden;
        padding: 0 5px;
        width: 210px;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 18px;
      }
      .iconfont,
      .anticon {
        width: 20px;
      }
    }
    .active {
      color: @primary-color;
    }
  }
}
</style>
