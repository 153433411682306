import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutComponent from '@/layout/layout-component.vue'
import { platform } from './platform'
import { workbench } from './workbench'
import { settings } from './settings'
import { archives } from './archives'
import { safe } from './safe'

// 将复杂的多层路由配置拆分成多个文件，便于维护
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/phoneLogin',
      name: 'phoneLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/chatLogin',
      name: 'chatLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/wChatLogin',
      name: 'wChatLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/supplier-signup',
      name: 'supplier-signup',
      component: () => import('../pages/entrance/supplier-signup/supplier-signup.vue'),
    },
    {
      path: '/supplier-account',
      name: 'supplier-account',
      component: () => import('../pages/entrance/supplier-signup/supplier-signup.vue'),
    },
    {
      path: '/organization',
      name: 'organization',
      component: () => import('../pages/entrance/organization-index.vue'),
      children: [
        {
          path: '/organization/join',
          name: 'organization-join',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
        {
          path: '/organization/create',
          name: 'organization-create',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
      ],
    },
    {
      path: '/edit-contract-template',
      name: 'editContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/preview-contract-template',
      name: 'previewContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/home',
      name: 'home',
      component: LayoutComponent,
      children: [
        {
          path: '/home/index',
          name: 'homeIndex',
          component: () => import('../pages/home/home-index.vue'),
        },
      ],
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../pages/entrance/invite-employee/invite-employee-index.vue'),
    },
    {
      path: '/invite-supplier',
      name: 'inviteSupplier',
      component: () => import('../pages/entrance/invite-supplier/invite-supplier-index.vue'),
    },
    archives,
    safe,
    settings,
    workbench,
    platform,
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../pages/error-404.vue'),
    },
  ],
})

export default router
