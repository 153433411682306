<template>
  <Modal
    maskClosable
    :title="t('pages.platform.user.staffInvite.title')"
    v-model:visible="inviteVisible"
    :width="532"
    :footer="null"
  >
    <div class="flex px-10 py-16">
      <!-- <div class="w-1/3 flex-shrink-0">
        <h3 class="-mt-8 pb-12 text-center">{{ t('pages.platform.user.staffInvite.scanIt') }}</h3>
        <div class="relative mx-auto w-[120px] h-[120px] bg-gray-100 group">
          <QrCode ref="qrcode" :value="inviteUrl" :size="120" class="invite-qrcode mx-auto" />
          <div
            class="hidden absolute inset-0 bg-white bg-opacity-90 active:bg-opacity-70 group-hover:flex justify-center items-center text-blue-600 cursor-pointer select-none"
            @click="handleSave"
          >
            {{ t('pages.platform.user.staffInvite.action.clickToSave') }}
          </div>
        </div>
        <p class="mt-4 text-gray-500 text-center">
          {{ t('pages.platform.user.staffInvite.sendQrCode') }}
          <br />
          {{ t('pages.platform.user.staffInvite.joinOrganization') }}
        </p>
      </div> -->
      <div class="w-[400px] shrink-0 px-6">
        <h3 class="-mt-8 pb-12 text-center">
          {{ t('pages.platform.user.staffInvite.invitationLink') }}
        </h3>
        <div class="flex h-[120px] flex-wrap content-center items-center justify-center">
          <div class="w-full">
            <a-input readonly :value="inviteUrl" />
          </div>
          <a-button type="primary" class="mt-4 w-full" @click="handleCopy">
            {{ t('action.copy') }}
          </a-button>
        </div>
        <p class="mt-4 text-center text-gray-500">
          {{ t('pages.platform.user.staffInvite.sendLink') }}
          <br />
          {{ t('pages.platform.user.staffInvite.clickJoin') }}
        </p>
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@cris/cris-ui'
import { copy } from '@/utils'
import { message } from 'ant-design-vue'
import { onMounted, ref, watch } from 'vue'
import QrCode from 'qrcode.vue'
import i18n from '@/i18n'
import { queryInviteOrganization } from '@/pages/entrance/entrance.api'

const { t } = i18n.global

const inviteVisible = ref(false)
const qrcode = ref(null)
const token = ref()
const inviteUrl = ref()
const organizationNames = ref()

const handleSave = () => {
  const canvas: HTMLCanvasElement | null = document.querySelector('.invite-qrcode')
  if (canvas) {
    const a = document.createElement('a')
    a.href = canvas.toDataURL('image/png')
    a.download = t('pages.platform.user.staffInvite.invitationQrCodeName')
    a.click()
  }
}

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  inviteToken: {
    type: String,
  },
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

watch(props, newProps => {
  if (newProps.visible) {
    inviteVisible.value = true
  } else {
    inviteVisible.value = false
    emit('close')
  }
})

watch(inviteVisible, newVisible => {
  if (!newVisible) {
    emit('close')
  }
})

watch(
  () => props.inviteToken,
  newToken => {
    token.value = newToken
    const url = window.location.href
    const [domainName, ...b] = url.split('/#')
    inviteUrl.value = `${domainName}/#/invite?invitedToken=${token.value}`
    queryInviteOrganization(token.value).then((res: any) => {
      const { organizationName } = res
      organizationNames.value = organizationName
    })
  },
)

const handleCopy = () => {
  copy(
    `${t('pages.platform.user.staff.invite.link.hint', {
      organizationName: organizationNames.value,
    })} ${inviteUrl.value}`,
  )
  message.success(t('message.copiedToClipboard', { field: t('field.link') }))
}
</script>
