import { createI18n } from 'vue-i18n'

interface Lang {
  key: string
  name: string
  message: { [KEY: string]: string }
}

const I18N_LOCALE_KEY = 'CRIS_I18N_LOCALE'

const languageList: Lang[] = [
  { key: 'zh-CN', name: '简体中文', message: {} },
  { key: 'en-US', name: 'English', message: {} },
]

const fallbackLocale = languageList[0].key

const modules = import.meta.glob(['../**/zh-CN.ts', '../**/en-US.ts'], { eager: true })

Object.keys(modules).forEach(modulePath => {
  const module = modules[modulePath] as Record<string, unknown>
  const messageData = module.default
  const matchedKey = modulePath.split('/').reverse()[0].replace('.ts', '')
  const matchedLang = languageList.find(lang => lang.key === matchedKey)
  if (matchedLang) {
    Object.assign(matchedLang.message, messageData)
  }
})

const storedLocale = localStorage.getItem(I18N_LOCALE_KEY)

const matchedStoredLocale =
  storedLocale && languageList.map(lang => lang.key).includes(storedLocale) ? storedLocale : ''

const presetLocale = matchedStoredLocale || navigator.language || fallbackLocale

const messages = Object.fromEntries(languageList.map(({ key, message }) => [key, message]))

const i18n = createI18n({
  locale: presetLocale,
  messages,
  fallbackLocale,
})

const {
  global: { t },
} = i18n

const setLocale = (locale: string) => {
  localStorage.setItem(I18N_LOCALE_KEY, locale)
  i18n.global.locale = locale
}

export { t, presetLocale, languageList, setLocale }

export default i18n
